import { html, render } from 'lit-html';
import * as navStyles from 'url:./style.css';
import * as outputStyles from 'url:../../output.css';
import * as brandStyles from 'url:../../brand.css';
import * as solarisLogo from 'url:../../assets/SOLARIS-01-LOGO.png';
import * as loginImg from 'url:../../assets/login-fingerprint.jpg';

class NavBar extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: "open" });
  }

  get mainText() {
    return this.getAttribute("mainText");
  }

  set mainText(val) {
    this.setAttribute("mainText", val);
  }

  static get observedAttributes() {
    return ["mainText"];
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.render();

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);

    document.addEventListener("click", this.handleClickOutside);

    const dropdownButton = this.shadow.querySelector("#dropdown-button");
    dropdownButton.addEventListener("click", this.toggleDropdown);

    const mobileMenuButton = this.shadow.querySelector("#mobile-menu-button");
    mobileMenuButton.addEventListener("click", this.toggleMobileMenu);
  }

  toggleDropdown(e) {
    e.stopPropagation();
    const dropdown = this.shadow.querySelector(".dropdown");
    dropdown.classList.toggle("hidden");
  }

  toggleMobileMenu(e) {
    e.stopPropagation();
    const mobileMenu = this.shadow.querySelector("#mobile-menu");
    mobileMenu.classList.toggle("hidden");

    const burgerIcon = this.shadow.querySelector("#burger-menu-icon");
    burgerIcon.classList.toggle("hidden");

    const closeIcon = this.shadow.querySelector("#close-menu-icon");
    closeIcon.classList.toggle("hidden");
  }

  handleClickOutside(e) {
    const dropdown = this.shadow.querySelector(".dropdown");
    const dropdownButton = this.shadow.querySelector("#dropdown-button");
    if (dropdown.classList.contains("hidden")) return;

    if (!dropdown.contains(e.target) && !dropdownButton.contains(e.target)) {
      dropdown.classList.add("hidden");
    }
  }

  async getSessionProfile() {
    try {
      const response = await fetch(`/user-profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.isAuthenticated) {
        let loggedInText = "Logged in";
        let logoutText = "Logout";
        if (data.displayName !== '') {
          loggedInText = `Logged in as ${data.displayName}`;
        } else {
          loggedInText = `Logged in as ${data.name}`;
        }
        this.shadow.querySelector("#loggedInTitle").innerHTML = loggedInText;
        this.shadow.querySelector("#loggedInTitle").href = `/profile`;
        this.shadow.querySelector("#logInMenuItem").innerHTML = logoutText;
        this.shadow.querySelector("#logInMenuItem").href = `/logout`;
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  render() {
    const mainClass = "rounded-md bg-gray-900 px-3 py-2 text-sm font-medium cyan-text future-font";
    const nonMainClass = "rounded-md px-3 py-2 text-sm font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font";
    const homeClass = this.mainText === "solaris" ? mainClass : nonMainClass;
    const characterClass = this.mainText === "character" ? mainClass : nonMainClass;
    const shipClass = this.mainText === "ship" ? mainClass : nonMainClass;

    const mainMobileClass = "block rounded-md bg-gray-900 px-3 py-2 text-base font-medium cyan-text future-font";
    const nonMainMobileClass = "block rounded-md px-3 py-2 text-base font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font";
    const homeClassMobile = this.mainText === "solaris" ? mainMobileClass : nonMainMobileClass;
    const characterClassMobile = this.mainText === "character" ? mainMobileClass : nonMainMobileClass;
    const shipClassMobile = this.mainText === "ship" ? mainMobileClass : nonMainMobileClass;

    const template = html`
      <link
        href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Creepster&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"
        rel="stylesheet"
      />
      <link href="${navStyles}" rel="stylesheet" />
      <link href="${outputStyles}" rel="stylesheet" />
      <link href="${brandStyles}" rel="stylesheet" />
      <link href="${brandStyles}" rel="stylesheet" />
      <nav class="bg-gray-800">
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div class="relative flex h-16 items-center justify-between">
            <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            
              <button type="button" class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false" id="mobile-menu-button">
                <span class="absolute -inset-0.5"></span>
                <span class="sr-only">Open main menu</span>
                <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" id="burger-menu-icon">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
                <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" id="close-menu-icon">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              
            </div>
            <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <a href="/"><div class="flex flex-shrink-0 items-center"><img class="h-6 w-auto" href="/" src="${solarisLogo}" alt="Solaris"></div></a>
              <div class="hidden sm:ml-6 sm:block">
                <div class="flex space-x-4">
                  <a href="/" class="${homeClass}">Home</a>
                  <div class="group relative inline-block">
                    <div class="flex justify-center">
                      <a href="/character-creator" class="${characterClass} text-center">
                        Character Creator
                      </a>
                    </div>
                    <div class="dropdown-menu absolute hidden group-hover:block bg-gray-800 shadow-lg mt-0 w-56 z-50">
                      <a href="/call-of-the-horizon" class="block px-4 py-2 adventure-font bg-gray-800 hover:bg-gray-700 hover:cyan-text">Call of the Horizon</a>
                      <a href="/grasp-of-the-void" class="block px-4 py-2 spooky-font bg-gray-800 hover:bg-gray-700 hover:cyan-text">Grasp of the Void</a>
                    </div>
                  </div>
                  <a href="/ship-creator" class="${shipClass} text-center">Ship Creator</a>
                  <a href="https://planetaria.wiki" target="_blank" class="rounded-md px-3 py-2 text-sm font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Wikiplanetaria</a>
                  <a href="https://vngrdcmdr.group" target="_blank" class="rounded-md px-3 py-2 text-sm font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font text-center">Vanguard Commander</a>
                </div>
              </div>
            </div>
            
            <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div class="relative ml-3">
                <div>
                  <button type="button" class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="dropdown-button" aria-expanded="false" aria-haspopup="true">
                    <span class="absolute -inset-1.5"></span>
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" src="${loginImg}" alt="Profile">
                  </button>
                </div>

                <div class="hidden dropdown absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md grey-bg py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none future-font cyan-border" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                  <a href="/profile"  class="block px-4 py-2 text-sm dark-cyan-text logInItem" role="menuitem" tabindex="-1" id="loggedInTitle">Not logged in</a>
                  <a href="/profile"  class="block px-4 py-2 text-sm dark-cyan-text logInItem" role="menuitem" tabindex="-1" id="profileTitle">Profile</a>
                  <a href="/login" class="block px-4 py-2 text-sm cyan-text future-font logInItem" role="menuitem" tabindex="0" id="logInMenuItem">Log In</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sm:hidden hidden" id="mobile-menu">
          <div class="space-y-1 px-2 pb-3 pt-2">
            <a href="/" class="${homeClassMobile}">Home</a>
            <a href="/character-creator" class="${characterClassMobile}">Character Creator</a>
            <a href="/call-of-the-horizon" class="block pl-8 text-s adventure-font bg-gray-800 hover:bg-gray-700">
              Call of the Horizon
            </a>
            <a href="/grasp-of-the-void" class="block pl-8 text-s spooky-font bg-gray-800 hover:bg-gray-700">
              Grasp of the Void
            </a>
            <a href="/ship-creator" class="${shipClassMobile}">Ship Creator</a>
            <a href="https://planetaria.wiki" target="_blank" class="block rounded-md px-3 py-2 text-base font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Wikiplanetaria</a>
            <a href="https://vngrdcmdr.group" target="_blank" class="block rounded-md px-3 py-2 text-base font-medium dark-cyan-text hover:bg-gray-700 hover:cyan-text future-font">Vanguard Commander</a>
          </div>
        </div>
        <div class="glow-line"></div>
      </nav>
    `;

    render(template, this.shadow);
    this.getSessionProfile();
  }
}

customElements.define("nav-bar", NavBar);
